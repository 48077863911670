export default ($axios) => ({
  getOriginList() {
    return $axios.$get('/dpbusinesscenter/msg/searchOrgSum')
  },
  getRecommendList() {
    return $axios.$get('/dpbusinesscenter/msg/searchRecommend')
  },
  getNewsList(params) {
    return $axios.$get('/dpbusinesscenter/tecnews/search', { params })
  },
  getNewsDeatil(params) {
    return $axios.$get('/dpbusinesscenter/tecnews/getByPk', { params, showMessage: false })
  },
  getNoticeList(params) {
    return $axios.$get('/dpbusinesscenter/tecplacard/search', { params })
  },
  getNoticeDeatil(params) {
    return $axios.$get('/dpbusinesscenter/tecplacard/getByPk', { params, showMessage: false })
  },
  getShopAdvertList() {
    return $axios.$get('/dpbusinesscenter/shop/search')
  },
  getAdvertList() {
    return $axios.$get('/dpbusinesscenter/tecad/searchCache')
  },
  getSumWeight() {
    return $axios.$get('/dpbusinesscenter/msg/sumOrderWeight')
  },
  getOrdWeight() {
    return $axios.$get('/dpbusinesscenter/msg/searchOrdWeight')
  },
  getExPrice() {
    return $axios.$get('/dpbusinesscenter/msg/searchExPrice')
  },
  addFeedback(param) {
    return $axios.$post('/dpbusinesscenter/tecfeedback/add', param)
  },
  getMessageList(params) {
    return $axios.$get('/dpbusinesscenter/msg/search', { params })
  },
  getBidPackList() {
    return $axios.$get('/dpbusinesscenter/bid/buyer/getMainPackageBidList')
  },
  getBidSingleList() {
    return $axios.$get('/dpbusinesscenter/bid/buyer/getMainSingleBidList')
  },
  getMsgCount() {
    return $axios.$get('/dpbusinesscenter/msg/countMsg')
  },
  // 已读
  readed(params) {
    return $axios.$get('/dpbusinesscenter/msg/readed', { params })
  },
  // 全部已读
  readedAll() {
    return $axios.$get('/dpbusinesscenter/msg/readedAll')
  },
  getNewsTypeList() {
    return $axios.$get('/dpbusinesscenter/tecnews/getEcNewsTypeList')
  },
  // 帮助中心模板
  dowmloadTemp() {
    return $axios.$get('/dpbusinesscenter/public/file/sellContract', { responseType: 'blob' })
  }
})
