export default ($axios) => ({
  addCart(param) {
    return $axios.$post('/dpbusinesscenter/orderCart/add', param, { showMessage: false })
  },
  addBatchCart(param) {
    return $axios.$post('/dpbusinesscenter/orderCart/batchAdd', param, { showMessage: false })
  },
  getGoodsCount() {
    return $axios.$get('/dpbusinesscenter/orderCart/getGoodsCount')
  },
  getOrderCartList() {
    return $axios.$get('/dpbusinesscenter/orderCart/search', { showMessage: false })
  },
  updateCart(param) {
    return $axios.$post('/dpbusinesscenter/orderCart/updateCart', param, { showMessage: false })
  },
  delAllGoods() {
    return $axios.$delete('/dpbusinesscenter/orderCart/delAllGoods', { showMessage: false })
  },
  delInvalidGoods() {
    return $axios.$delete('/dpbusinesscenter/orderCart/delInvalidGoods', { showMessage: false })
  },
  delGoods(params) {
    return $axios.$delete('/dpbusinesscenter/orderCart/delGoods', { params }, { showMessage: false })
  },
  createOrderValidatePage(param) {
    return $axios.$post('/dpbusinesscenter/orderCart/createOrderValidatePage', param, { showMessage: false })
  },
  validateBuyNow(param) {
    return $axios.$post('/dpbusinesscenter/orderCart/validateBuyNow', param, { showMessage: false })
  },
  displayContract(type, param) {
    return $axios.$post(`/dpbusinesscenter/contract/displayContract?type=${type}`, param, { showMessage: false })
  },
  signContract(param) {
    return $axios.$post('/dpbusinesscenter/contract/sign', param, { timeout: 20 * 1000, showMessage: false })
  },
  retrySign(param) {
    return $axios.$post('/dpbusinesscenter/contract/retrySign', param, { showMessage: false })
  },
  getSecurity(params) {
    return $axios.$get('/dpbusinesscenter/account/security', { params })
  },
  getCartExport() {
    return $axios.$get('/dpbusinesscenter/orderCart/export', { responseType: 'blob' })
  }
})
