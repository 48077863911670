export const statusMap = new Map([
  [0, '停用'],
  [1, '正常']
])

// 客服热线
export const HOT_LINE = '0510-86186598'

// 客服热线
export const C2M_HOT_LINE = '400-079-1776'

// 仓库最大数量
export const MAX_WAREHOUSE_NUM = 20

export const waterName = 'www.tegang365.com'

export const JIN_MAO = '安徽宝景供应链管理有限公司'

export const JIN_MAO_ID = 'C0014177'

// 到期前一星期
export const temporaryTime = 7 * 24 * 60 * 60 * 1000

export const PREFIXS = ['usercenter', 'sellercenter', 'buyercenter', 'moneycenter']

// 加工仓库ids
export const C2M_REPO_IDS = []
