export default ($axios) => ({
  getCategoryList() {
    return $axios.$get('/dpbusinesscenter/goods/category/getCPTree')
  },
  getTabQuantity(param) {
    return $axios.$post('/dpbusinesscenter/search/getTabQuantity', param)
  },
  getSearchCondition(param) {
    return $axios.$post('/dpbusinesscenter/goods/listed/getSearchCondition', param, {
      showMessage: false
    })
  },
  getPMInfo() {
    return $axios.$get('/dpbusinesscenter/goods/category/getPMInfo')
  },
  getMaterialInfo() {
    return $axios.$get('/dpbusinesscenter/goodsUnlisted/goodsMaterialLList')
  }
})
