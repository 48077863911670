export const actions = {
  async nuxtServerInit({ commit, dispatch }, { app, req }) {
    const token = app.$cookies.get('token') || ''
    const refreshToken = app.$cookies.get('refreshToken') || ''
    const corpId = app.$cookies.get('corpId', { parseJSON: false }) || ''
    if (token) {
      try {
        commit('auth/SET_TOKEN', token)
        commit('auth/SET_REFRESH_TOKEN', refreshToken)
        commit('app/SET_CORPID', corpId)
        await dispatch('app/GET_USERINFO', app)
        await dispatch('app/GET_PERMISSION', app)
        await dispatch('app/GET_TRANSACTIONPARAMS', app)
      } catch {
        console.log('error')
      }
    }
  }
}
