export default ($axios) => ({
  getPayChannel(params) {
    return $axios.$post('/dpbusinesscenter/pay/getPayChannel', params)
  },
  payOrder(param) {
    return $axios.$post('/dpbusinesscenter/pay/pay', param, { showMessage: false, timeout: 20 * 1000 })
  },
  getPayInfo(params) {
    return $axios.$get('/dpbusinesscenter/pay/getPayInfo', { params })
  },
  createOrder(params) {
    return $axios.$post('/dpbusinesscenter/orderCommon/createOrder', params)
  },
  getOrderInfo(params) {
    return $axios.$post('/dpbusinesscenter/orderCommon/getOrderInfo', params)
  }
})
