const bdStatsID = '1093513427e809c1dbc6aded4b3dd580'
/**
 * 百度统计
 */
export default ({ app: { router }, store }) => {
  router.afterEach((to, from) => {
    if (process.client && process.env.NODE_ENV === 'production') {
      if (window.location.host.includes('tegang365.com')) {
        ;(function () {
          const hm = document.createElement('script')
          hm.src = `https://hm.baidu.com/hm.js?${bdStatsID}`
          hm.async = true
          const s = document.getElementsByTagName('script')[0]
          s.parentNode.insertBefore(hm, s)
        })()
      }
    }
  })
}
