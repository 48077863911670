export default ($axios) => ({
  createDemandInfo(params) {
    return $axios.$post('/dpbusinesscenter/demand/buyer/createDemandInfo', params)
  },
  updateDemandInfo(params) {
    return $axios.$post('/dpbusinesscenter/demand/buyer/updateDemandInfo', params)
  },
  confirmRelease(params) {
    return $axios.$post('/dpbusinesscenter/demand/buyer/confirmRelease', params)
  },
  uploadFile(params) {
    return $axios.$post('/dpbusinesscenter/cloudStorage/uploads', params)
  },
  getC2MContract(params) {
    return $axios.$get('/dpbusinesscenter/tpcprocessordercontract/displayClientContract', { params })
  },
  getSaleContract(params) {
    return $axios.$get('/dpbusinesscenter/tpcprocessordercontract/displayBuySellContract', { params })
  }
})
